<template>
  <v-card class="pa-4 admin-report-card">
    <v-row class="report-wrap">
      <v-col
        md="12"
        sm="12"
        cols="12"
        class="d-flex align-center">
        <p class="text-h6">
          POS Sales Report
        </p>
      </v-col>
      <v-col
        xl="2"
        lg="2"
        md="2"
        sm="4"
        cols="12"
        class="d-flex align-center">
        <v-select
          v-model="brandSelected"
          label="เลือก Brand"
          :items="allBrand"
          :disabled="fetching"
          outlined
          auto-select-first
          dense
          hide-details
          @input="fetchReport" />
      </v-col>
      <v-col
        xl="2"
        lg="2"
        md="2"
        sm="4"
        cols="12"
        class="d-flex align-center">
        <WarehouseSelect
          v-model="warehouseSelected"
          :items="warehouses"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          label="Warehouses"
          outlined
          :disabled="fetching" />
      </v-col>
      <v-col
        xl="2"
        lg="2"
        md="2"
        sm="4"
        cols="12"
        class="d-flex align-center">
        <v-select
          v-model="posSelected"
          label="เลือก POS"
          :items="allPos"
          :disabled="fetching || warehouseSelected == 'all' || warehouseSelected == null"
          outlined
          dense
          hide-details
          @input="fetchReport" />
      </v-col>
      <v-col
        xl="5"
        lg="5"
        md="5"
        sm="10"
        cols="12"
        class="d-flex align-center">
        <date-picker
          v-model="dateSelected"
          :disabled="fetching" />
      </v-col>
      <v-col
        xl="1"
        lg="1"
        md="1"
        sm="2"
        cols="2">
        <v-btn
          :loading="fetching"
          @click="fetchReport()">
          Go
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        class="d-flex flex-wrap justify-center">
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Count Bill</div>
            <p class="title text--primary">
              {{ summary.countBill | showNumberFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Items</div>
            <p class="title text--primary">
              {{ summary.totalItem | showNumberFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Full Price</div>
            <p class="title text--primary">
              {{ summary.totalFullPrice | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Unit Discount</div>
            <p
              v-if="summary.totalFullPrice"
              class="title text--primary">
              {{ summary.totalFullPrice - summary.totalPrice | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Bill Discount</div>
            <p class="title text--primary">
              {{ summary.totalBillDiscount | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total ship cost</div>
            <p class="title text--primary">
              {{ summary.totalShipCost | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-card
          outlined
          class="ma-4">
          <v-card-text class="text-center">
            <div>Total Bill Net</div>
            <p class="title text--primary">
              {{ summary.totalBillNet | showFullPriceFormat() }}
            </p>
          </v-card-text>
        </v-card>
        <v-btn
          color="success"
          min-width="15%"
          class="mx-2 mt-9"
          :loading="exportLoading || fetching"
          @click="confirmExport">
          <v-icon class="mr-1">
            mdi-export
          </v-icon>
          Export CSV
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="options.itemsPerPage"
          :server-items-length="total"
          :options.sync="options"
          :loading="fetching"
          dense
          :footer-props="{'items-per-page-options': [15, 25, 50, 100]}"
          @update:options="fetchReport()">
          <template #[`item.orderId`]="{ item }">
            <span
              :class="{
                'color-void': item.currentState === 'void' || item.currentState === 'exchange_return'
              }"> {{ item.orderId }}</span>
          </template>
          <template #[`item.sumFullPrice`]="{ item }">
            {{ item.sumFullPrice | showNumberFormat() }}
          </template>
          <template #[`item.unitDiscount`]="{ item }">
            {{ item.unitDiscount | showNumberFormat() }}
          </template>
          <template #[`item.sumPrice`]="{ item }">
            {{ item.sumPrice | showNumberFormat() }}
          </template>
          <template #[`item.billDiscount`]="{ item }">
            {{ item.billDiscount | showNumberFormat() }}
          </template>
          <template #[`item.shipCost`]="{ item }">
            {{ item.shipCost | showNumberFormat() }}
          </template>
          <template #[`item.net`]="{ item }">
            {{ item.net | showNumberFormat() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="d-flex">
        <h2>Nationality</h2>
        <v-btn
          class="ml-1"
          color="success"
          icon
          :loading="fetching"
          @click="exportCsvNationality">
          <v-icon>
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="nationalitySummaryHeaders"
          :items="nationalitySummary"
          :items-per-page="-1"
          :loading="fetching"
          dense
          hide-default-footer>
          <template #[`item.countBill`]="{ item }">
            {{ item.countBill | showNumberFormat() }}
          </template>
          <template #[`item.totalItem`]="{ item }">
            {{ item.totalItem | showNumberFormat() }}
          </template>
          <template #[`item.totalFullPrice`]="{ item }">
            {{ item.totalFullPrice | showNumberFormat() }}
          </template>
          <template #[`item.totalPrice`]="{ item }">
            {{ item.totalPrice | showNumberFormat() }}
          </template>
          <template #[`item.totalBillDiscount`]="{ item }">
            {{ item.totalBillDiscount | showNumberFormat() }}
          </template>
          <template #[`item.totalBillNet`]="{ item }">
            {{ item.totalBillNet | showNumberFormat() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="d-flex">
        <h2>Gender</h2>
        <v-btn
          class="ml-1"
          color="success"
          icon
          :loading="fetching"
          @click="exportCsvGender">
          <v-icon>
            mdi-microsoft-excel
          </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="genderSummaryHeaders"
          :items="genderSummary"
          :items-per-page="-1"
          :loading="fetching"
          dense
          hide-default-footer>
          <template #[`item.id`]="{ item }">
            <span class="text-capitalize">
              {{ item.id || 'Doesn\'t describe' }}
            </span>
          </template>
          <template #[`item.countBill`]="{ item }">
            {{ item.countBill | showNumberFormat() }}
          </template>
          <template #[`item.totalItem`]="{ item }">
            {{ item.totalItem | showNumberFormat() }}
          </template>
          <template #[`item.totalFullPrice`]="{ item }">
            {{ item.totalFullPrice | showNumberFormat() }}
          </template>
          <template #[`item.totalPrice`]="{ item }">
            {{ item.totalPrice | showNumberFormat() }}
          </template>
          <template #[`item.totalBillDiscount`]="{ item }">
            {{ item.totalBillDiscount | showNumberFormat() }}
          </template>
          <template #[`item.totalBillNet`]="{ item }">
            {{ item.totalBillNet | showNumberFormat() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { ExportToCsv } from 'export-to-csv'
import { mapGetters } from 'vuex'
import { getAuthDecode } from '@/assets/js/Authentication'
import NewReportProvider from '@/resources/NewReportProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import PosManagementProvider from '@/resources/PosManagementProvider'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import DatePicker from '../components/DatesPicker.vue'

const ProductAttributeService = new ProductAttributeProvider()
const NewReportService = new NewReportProvider()
const posManagementService = new PosManagementProvider()

export default {
  components: {
    DatePicker,
    WarehouseSelect
  },
  data () {
    return {
      exportLoading: false,
      fetching: false,
      warehouseSelected: null,
      options: {
        page: 1,
        itemsPerPage: 15,
        sortDesc: [true],
        sortBy: ['createdAt']
      },
      brandSelected: 'all',
      allBrand: [],
      nationalitySummary: [],
      genderSummary: [],
      summary: {},
      nationalitySummaryHeaders: [
        { text: 'Nation', value: 'id', width: 100 },
        { text: 'Count', value: 'countBill', align: 'right', width: 150 },
        { text: 'Total Item', value: 'totalItem', align: 'right', width: 150 },
        { text: 'Total Full Price', value: 'totalFullPrice', align: 'right', width: 150 },
        { text: 'Total Price', value: 'totalPrice', align: 'right', width: 120 },
        { text: 'Total Bill Discount', value: 'totalBillDiscount', align: 'right', width: 150 },
        { text: 'Total Bill Net', value: 'totalBillNet', align: 'right', width: 120 }
      ],
      genderSummaryHeaders: [
        { text: 'Gender', value: 'id', width: 100 },
        { text: 'Count', value: 'countBill', align: 'right', width: 150 },
        { text: 'Total Item', value: 'totalItem', align: 'right', width: 150 },
        { text: 'Total Full Price', value: 'totalFullPrice', align: 'right', width: 150 },
        { text: 'Total Price', value: 'totalPrice', align: 'right', width: 120 },
        { text: 'Total Bill Discount', value: 'totalBillDiscount', align: 'right', width: 150 },
        { text: 'Total Bill Net', value: 'totalBillNet', align: 'right', width: 120 }
      ],
      headers: [
        { text: 'Bill Id', value: 'orderId', width: 170 },
        { text: 'Create Time', value: 'createdTime', width: 150 },
        { text: 'Item Amount', value: 'itemAmount', align: 'right', width: 120, sortable: false },
        { text: 'Sub Total', value: 'sumFullPrice', align: 'right', width: 120, sortable: false },
        { text: 'Unit Discount', value: 'unitDiscount', align: 'right', width: 150, sortable: false },
        { text: 'Promotion Code', value: 'promotionCode', align: 'center', width: 150 },
        { text: 'Total', value: 'sumPrice', align: 'right', width: 120 },
        { text: 'Bill Discount', value: 'billDiscount', align: 'right', width: 120 },
        { text: 'Ship cost', value: 'shipCost', align: 'right', width: 150 },
        { text: 'Net', value: 'net', align: 'right', width: 150 },
        { text: 'Channel', value: 'channel', align: 'center', width: 150 },
        { text: 'Warehouse', value: 'warehouse', align: 'center', width: 150 },
        { text: 'Nationality', value: 'nationality', align: 'center', width: 150 },
        { text: 'Gender', value: 'gender', align: 'center', width: 150 },
        { text: 'POS Id', value: 'posId', align: 'center', width: 150 }
      ],
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()],
      items: [],
      total: 0,
      posSelected: 'all',
      allPos: []
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return [
        {
          id: null,
          name: 'All',
          code: 'all'
        },
        ...this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
      ]
    }
  },
  watch: {
    dateSelected: {
      handler () {
        this.fetchReport()
      },
      deep: true
    },
    warehouseSelected () {
      this.getPos()
      this.posSelected = 'all'
      this.fetchReport()
    }
  },
  async mounted () {
    await this.getBrand()
    this.fetchReport()
  },
  methods: {
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.allBrand = [
        {
          text: 'All',
          value: 'all'
        },
          ...data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    async getPos () {
      this.allPos = []
      try {
        const { data } = await posManagementService.getPosDeviceByWarehouseId({ warehouse: this.warehouseSelected })
        this.allPos = [
          {
            text: 'All',
            value: 'all'
          },
          ...data.map((pos) => ({
            text: pos.posId,
            value: pos.id
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    resetData () {
      this.items = []
      this.summary = {}
      this.nationalitySummary = []
      this.genderSummary = []
      this.total = 0
    },
    async fetchReportNationality () {
      try {
        const { data } = await NewReportService.getNewNationality({
          channel: 'pos',
          brand: this.brandSelected,
          warehouse: this.warehouseSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.nationalitySummary = data
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      }
    },
    async fetchReportGender () {
      try {
        const { data } = await NewReportService.getNewGender({
          channel: 'pos',
          brand: this.brandSelected,
          warehouse: this.warehouseSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.genderSummary = data
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      }
    },
    async fetchReportSummary () {
      try {
        const { data } = await NewReportService.getNewReportSummaryWarehouse({
          channel: 'pos',
          brand: this.brandSelected,
          warehouse: this.warehouseSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.summary = data
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      }
    },
    async fetchReport () {
      this.resetData()
      this.fetching = true
      await Promise.all([
        this.fetchMainReport(),
        this.fetchReportSummary(),
        this.fetchReportNationality(),
        this.fetchReportGender()
      ])
      this.fetching = false
    },
    async fetchMainReport () {
      try {
        const { data } = await NewReportService.getNewReportPos({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy[0],
          sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc',
          warehouse: this.warehouseSelected,
          brand: this.brandSelected,
          pos: this.posSelected,
          channel: 'pos',
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })
        this.total = data.total
          this.items = data.results.map((re) => ({
          ...re,
          createdTime: this.$dayjs(re.createdTime).format('DD-MM-YYYY HH:mm'),
          unitDiscount: re.sumFullPrice - re.sumPrice
        }))
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${error.message}`,
          type: 'error'
        })
      }
    },
    exportCsvNationality () {
      const startDate = this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const endDate = this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const options = {
          filename: `NATIONALLITY_${startDate}_${endDate}_${this.warehouseSelected}`,
          showLabels: true,
          useKeysAsHeaders: true
        }
      const csvExporter = new ExportToCsv(options)

      const data = this.nationalitySummary.map((item) => {
        const result = {}
        this.nationalitySummaryHeaders.forEach((h) => {
          result[h.text] = item[h.value]
        })

        return result
      })
      csvExporter.generateCsv(data)
    },
    exportCsvGender () {
      const startDate = this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const endDate = this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format('DD-MM-YYYY')
      const options = {
          filename: `GENDER_${startDate}_${endDate}_${this.warehouseSelected}`,
          showLabels: true,
          useKeysAsHeaders: true
        }
      const csvExporter = new ExportToCsv(options)

      const data = this.genderSummary.map((item) => {
        const result = {}
        this.genderSummaryHeaders.forEach((h) => {
          result[h.text] = item[h.value]
        })

        return result
      })
      csvExporter.generateCsv(data)
    },
    download (url, fileName) {
      let tmpFilename = fileName

      if (!fileName) {
        tmpFilename = this.$dayjs().format('YYYYMMDDHHmmss')
      }
      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.download = tmpFilename
      downloadLink.click()
    },
    confirmExport () {
      const { email } = getAuthDecode()

      this.$store.dispatch('Components/setModal', {
        value: true,
        title: 'คุณยืนยันที่จะ Export ใช่หรือไม่ ?',
        message: `หากข้อมูล Export เสร็จแล้ว ไฟล์จะส่งไปใน E-Mail : "${email}"`,
        confirmText: 'ยืนยัน',
        confirmType: 'success',
        cancelType: '',
        cancelText: 'ปิด',
        onConfirm: () => this.exportCsv()
      })
    },
    async exportCsv () {
      try {
        this.exportLoading = true

        const { data } = await NewReportService.getExportProWarehouse({
          page: this.options.page,
          limit: this.options.itemsPerPage,
          sortBy: this.options.sortBy[0],
          sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc',
          channel: 'pos',
          warehouse: this.warehouseSelected,
          brand: this.brandSelected,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          pos: this.posSelected
        })

        // this.$store.dispatch('Components/setModal', {
        //   value: true,
        //   title: 'แจ้งเตือน',
        //   message: 'การ Export ข้อมูลอาจจะใช้เวลา 3-5 นาที ขึ้นอยู่กับขนาดของข้อมูล',
        //   confirmText: 'ยืนยัน',
        //   confirmType: 'success',
        //   confirmOnly: true,
        //   onConfirm: () => {}
        // })
        if (data) {
          this.download(data)
        }
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      } finally {
        this.exportLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/gw-variables.scss';
.color-void {
  color: $bill-status-void;
}
</style>
